import PropTypes from 'prop-types';

import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';

/** @namespace Husqvarna/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    static propTypes = {
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isLoading: true
    };

    renderMain() {
        return (
            <div block="Loader" elem="Main">
                <span />
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="LoaderWrapper">
                <svg width="20px" height="12px">
                    <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                    <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                </svg>
            </div>
        );
    }
}

export default LoaderComponent;
